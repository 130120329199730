import '../css/Circle.css';
import { Header, List, Icon } from 'semantic-ui-react';

const CartGoals = (props) => (
    <div>
        <Header as='h3' color='blue' style={{ marginTop: '20px' }}>
            RAGGIUNGI TUTTI GLI OBIETTIVI
        </Header>
        <List style={{ marginTop: '20px', marginBottom: '20px' }}>
            <List.Item>
                <Icon name='winner' color={props.goalBioAchieved ? 'green' : 'grey'} disabled={!props.goalBioAchieved} />
                <List.Content>
                    <Header as='h3' color={props.goalBioAchieved ? 'green' : 'grey'}>
                        Almeno la metà dei prodotti sono Bio
                    </Header>
                </List.Content>
            </List.Item>
            <List.Item>
                <Icon name='winner' color={props.goalFibersAchieved ? 'green' : 'grey'} disabled={!props.goalFibersAchieved} />
                <List.Content>
                    <Header as='h3' color={props.goalFibersAchieved ? 'green' : 'grey'}>
                        Una media di 10 grammi di fibre per prodotto
                    </Header>
                </List.Content>
            </List.Item>
            <List.Item>
                <Icon name='winner' color={props.goalNoAdditivesAchieved ? 'green' : 'grey'} disabled={!props.goalNoAdditivesAchieved} />
                <List.Content>
                    <Header as='h3' color={props.goalNoAdditivesAchieved ? 'green' : 'grey'}>
                        Almeno metà dei prodotti sono senza additivi
                    </Header>
                </List.Content>
            </List.Item>
        </List>
    </div>
)

export default CartGoals;
