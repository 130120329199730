import { Container, Segment, Grid, Divider } from 'semantic-ui-react';
import Top from '../components/Top';
import CategoriesList from '../components/CategoriesList';
import Cart from '../components/Cart';
import CartAction from '../components/CartAction';
import Highlight from '../components/Highlight';
import { useHistory } from "react-router";

const Home = () => {

    const history = useHistory();

    const onCategorySelected = (categoryId) => {
        history.push({
            pathname: '/category/' + categoryId
        });
    }

    return (
        <Container fluid>
            <Grid centered stackable>
                <Grid.Row color='blue'>
                    <Top />
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={4}>
                        <Segment raised>
                            <CategoriesList active={-1} onCategorySelected={onCategorySelected} />
                        </Segment>
                    </Grid.Column>
                    <Grid.Column width={7}>
                        <Segment raised>
                            <Highlight />
                        </Segment>
                    </Grid.Column>
                    <Grid.Column width={4} textAlign='center'>
                        <Segment raised>
                            <Cart />
                            <Divider />
                            <CartAction action='checkout' />
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    )
}

export default Home;
