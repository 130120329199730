import { Component } from 'react';
import { Table, Image, Select, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';

class CheckoutTable extends Component {

    constructor(props) {
        super(props);
    }

    buildProductsList = (props) => {
        var options = [
            { key: '1', text: '1', value: 1 },
            { key: '2', text: '2', value: 2 },
            { key: '3', text: '3', value: 3 },
            { key: '4', text: '4', value: 4 },
            { key: '5', text: '5', value: 5 },
            { key: '6', text: '6', value: 6 },
            { key: '7', text: '7', value: 7 },
            { key: '8', text: '8', value: 8 },
            { key: '9', text: '9', value: 9 },
            { key: '10', text: '10', value: 10 }
        ];

        if (this.props.cart.size === 0) {
            return 'Carrello vuoto';
        }

        return this.props.cart.map((cartElement) => {

            var product = cartElement.product;

            return (
                <Table.Row textAlign='center' key={product.id}>
                    <Table.Cell>
                        <Image src={product.url_image} size='tiny' spaced />
                    </Table.Cell>
                    <Table.Cell>
                        <span>{product.name}, {product.brand}</span>
                    </Table.Cell>
                    <Table.Cell>
                        <Select
                            selection
                            disabled
                            value={cartElement.quantity}
                            fluid
                            options={options}
                            placeholder='1'
                        />
                    </Table.Cell>
                    <Table.Cell>
                        <Button icon='trash' basic onClick={(removedProduct) => this.props.onRemoveProductClick(product)} />
                    </Table.Cell>
                    <Table.Cell>&euro; {product.price}</Table.Cell>
                    <Table.Cell>&euro; {product.price*cartElement.quantity}</Table.Cell>
                </Table.Row>
            );
        });
    }

    render() {
        var totalPrice = 0;
        this.props.cart.map((cartElement) => {
            totalPrice += cartElement.product.price*cartElement.quantity;
        });

        return (
            <Table celled unstackable>
                <Table.Header>
                    <Table.Row textAlign='center'>
                        <Table.HeaderCell>Immagine</Table.HeaderCell>
                        <Table.HeaderCell>Prodotto</Table.HeaderCell>
                        <Table.HeaderCell>Q.tà</Table.HeaderCell>
                        <Table.HeaderCell>Rimuovi</Table.HeaderCell>
                        <Table.HeaderCell>Prezzo unitario</Table.HeaderCell>
                        <Table.HeaderCell>Prezzo totale</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {this.buildProductsList(this.props)}
                </Table.Body>
                <Table.Footer fullWidth>
                    <Table.Row textAlign='center'>
                        <Table.HeaderCell colSpan='4'></Table.HeaderCell>
                        <Table.HeaderCell>Totale</Table.HeaderCell>
                        <Table.HeaderCell>&euro; {totalPrice.toFixed(2)}</Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table >
        );
    }
}

const mapStateToProps = state => {
    var products = [];

    state.cart_rdc.cart.forEach(
        cartElement => products.push(cartElement.product
        ));

    return {
        cart: state.cart_rdc.cart,
        products: products
    }
};

const mapDispatchToProps = dispatch => {
    return {
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutTable);
