import { Container, Segment, Grid, Divider, Loader } from 'semantic-ui-react';
import Top from '../components/Top';
import CategoriesList from '../components/CategoriesList';
import CategoryItems from '../components/CategoryItems';
import Cart from '../components/Cart';
import CartAction from '../components/CartAction';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from "react-router-dom";
import * as actionTypes from '../store/actions';
import { Component } from 'react';
import API from '../api';

class Category extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentCategoryId: this.props.match.params.id,
            products: [],
            loading: false
        };
    }

    onCategorySelected = (categoryId) => {

        this.props.history.push({
            pathname: '/category/' + categoryId
        });

        this.getProducts(categoryId);
    }

    addProductCart = async(newProduct) => {

        const productsIds = this.props.products.map((element) => {
            return element.id;
        });
        productsIds.push(newProduct.id);

        this.setState({
            loading: true
        });

        try {
            let response = await API.updateCart(productsIds);

            this.setState({
                loading: false
            });

            if (response.data.data != null) {

                var data = response.data.data;

                this.props.addProductCart(newProduct, data.score, data.proteins, data.carbos, data.fats, data.fibers, data.goalBio, data.goalNoAdditives);
                this.getSuggestions(productsIds, data.score);

            } else {
                console.log('Error while updating the cart info.');
            }
        } catch (error) {
            this.setState({
                loading: false
            });
            console.log('Error while getting the cart info.');
        }
        
    }

    getSuggestions = async (productsIds, score) => {

        if (productsIds.length == 0) {
            this.setState({
                suggestions: [],
                loading: false
            });

            this.props.addSuggestions([]);

            return
        }

        try {
            let response = await API.getSuggestions(productsIds, score);

            if (response.data.data != null) {

                this.setState({
                    loading: false
                });

                this.props.addSuggestions(response.data.data);

            } else {
                this.setState({
                    loading: false
                });
                console.log('Error while updating suggestions.');
            }
        } catch (error) {
            this.setState({
                loading: false
            });
            console.log('Error while getting suggestions.');
        }
    }

    getProducts = async (categoryId) => {
        try {
            let response = await API.getProducts(categoryId);

            if (response.data.data != null) {
                this.setState({
                    currentCategoryId: categoryId,
                    products: response.data.data
                });
            } else {
                console.log('Error while getting the products list.');
            }
        } catch (error) {
            console.log('Error while getting the products list.');
        }
    }

    componentDidMount() {
        this.getProducts(this.state.currentCategoryId);
    }

    render() {
        return (
            <div>
                <Container fluid>
                    <Grid centered stackable>
                        <Grid.Row color='blue'>
                            <Top />
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={4}>
                                <Segment raised>
                                    <CategoriesList categoryId={this.state.currentCategoryId} onCategorySelected={this.onCategorySelected} />
                                </Segment>
                            </Grid.Column>
                            <Grid.Column width={7}>
                                <Segment raised>
                                    <Loader inline='centered' active={this.state.loading} disabled={!this.state.loading} />
                                    <CategoryItems
                                        categoryId={this.state.currentCategoryId}
                                        products={this.state.products}
                                        onAddProductClick={(id) => { this.addProductCart(id) }} />
                                </Segment>
                            </Grid.Column>
                            <Grid.Column width={4} textAlign='center'>
                                <Segment raised>
                                    <Cart />
                                    <Divider />
                                    <CartAction action='checkout' />
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </div>);
    }
}

const mapStateToProps = state => {
    var products = [];

    state.cart_rdc.cart.forEach(
        cartElement => products.push(cartElement.product
        ));

    return {
        products: products,
        score: state.cart_rdc.score,
        suggestions: state.cart_rdc.suggestions
    }
};

const mapDispatchToProps = dispatch => {
    return {
        addProductCart: (value, score, proteinsPercentage, carbosPercentage, fatsPercentage, fibersAverageQuantity, goalBioAchieved, goalNoAdditivesAchieved) => dispatch({
            type: actionTypes.ADD_PRODUCT, 
            product: value,
            score: score,
            proteinsPercentage: proteinsPercentage,
            carbosPercentage: carbosPercentage,
            fatsPercentage: fatsPercentage,
            fibersAverageQuantity: fibersAverageQuantity,
            goalBioAchieved: goalBioAchieved,
            goalNoAdditivesAchieved: goalNoAdditivesAchieved
        }),
        addSuggestions:(suggestions) => dispatch({
            type: actionTypes.ADD_SUGGESTIONS, 
            suggestions: suggestions
        })
    }
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(Category);
