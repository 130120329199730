import { Card, Header, Image, Grid, Button, Label } from 'semantic-ui-react';

const Suggestions = (props) => {
    var rows = [];
    if (props.products != undefined) {
        rows = props.products.map(product => {
            return (
                <Grid.Column>
                    <Card fluid>
                        <Card.Content textAlign='center'>
                            <Image src={product.url_image} size='tiny' />
                        </Card.Content>
                        <Card.Content className='test' textAlign='center'>
                            <Label size='small' fluid className='label-test' color='blue'>{product.text.toUpperCase()}</Label>
                        </Card.Content>
                        <Card.Content textAlign='center'>
                            <p>{product.name + " "}</p><p><b>{product.brand}</b></p>
                            <Button basic color='green' onClick={()=>props.onAddProductClick(product)} >+ Aggiungi</Button>
                        </Card.Content>
                    </Card>
                </Grid.Column>
            )
        });
    }

    var placeholderText = null
    if (props.products.length == 0 && props.cartLength == 0) {
        placeholderText = <Label style={{ marginBottom: '10px' }} basic pointing color='green' size='big'>Inizia ad aggiungere prodotti al tuo carrello, sulla destra vedrai un punteggio che indica quanto è nutrizionalmente bilanciato il tuo carrello. In questa sezione troverai i nostri suggerimenti per rendere la tua spesa ancora migliore.</Label>
    } else if (props.products.length == 0 && props.cartLength > 1) {
        placeholderText = <Label style={{ marginBottom: '10px' }} basic pointing color='green' size='big'>Complimenti! La tua spesa è praticamente perfetta, non abbiamo altri suggerimenti per te.</Label>
    }

    return (
        <div>
            <Header as='h4' color='grey' style={{ marginTop: '40px' }}>MIGLIORA LA QUALITÀ NUTRIZIONALE DEL TUO CARRELLO</Header>
            {placeholderText}
            <Grid columns={3} stackable celled>
                <Grid.Row>
                    {rows}
                </Grid.Row>
            </Grid>
        </div>
    );
}

export default Suggestions;
