import axios from 'axios';
import * as config from './config';

class API {

    getCategories = () => {
        return axios({
            method: 'get',
            url: config.BASE_API_URL + 'categories'
        });
    }

    getProducts = (categoryId) => {
        let params = { category_id: categoryId }

        return axios({
            method: 'get',
            url: config.BASE_API_URL + 'products',
            params: params
        });
    }

    getSuggestions = (productIds, current_score) => {

        var params = new URLSearchParams();

        if (productIds != null && productIds.length > 0 && current_score != null) {

            productIds.forEach(id => {
                params.append('product_ids[]', id);
            });

            params.append('current_score', current_score)

            return axios({
                method: 'get',
                url: config.BASE_API_URL + 'suggestions',
                params: params
            });
        }
    }

    updateCart = (productsIds) => {

        var bodyFormData = new FormData();

        bodyFormData.append('product_ids', JSON.stringify(productsIds));

        return axios({
            headers: {
                'Content-type': 'application/form-data'
            },
            url: config.BASE_API_URL + 'cart',
            method: 'post',
            data: bodyFormData
        });
    }
}

export default (new API());