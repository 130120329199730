import { Header, Image, Button, Grid, Card, Loader } from 'semantic-ui-react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from "react-router-dom";
import * as actionTypes from '../store/actions';
import '../css/Highlight.css';
import Suggestions from './Suggestions.js'
import API from '../api';

var product1 = {
    "id": 1,
    "name": "Vita Mix",
    "brand": "NutClub",
    "price": 1.99,
    "url_image": "https://www.supermercato24.it/asset/smhd/351e9/553f7/5da01/285561129311949312_img.jpg"
}

var product2 = {
    "id": 2,
    "name": "Cappelletti al Prosciutto Crudo",
    "brand": "Buitoni",
    "price": 1.99,
    "url_image": "https://www.supermercato24.it/asset/smhd/aa57a/7c4ca/b8c48/1310473639208574208_img.jpg"
}

var product3 = {
    "id": 4,
    "name": "Ortaiola con Spinaci",
    "brand": "Amadori",
    "price": 1.99,
    "url_image": "https://coopmasteronline.it/cz/caricamenti/product_images/002691740.jpg"
}

class Highlight extends Component {

    constructor(props) {
        super(props);
        this.state = {
            suggestions: props.suggestions,
            loading:false
        };
    }

    render() {
        return (
            <div>
                <div className='cta-container'>
                    <Image src='https://www.gdonews.it/wp-content/uploads/2020/02/nutella-biscuits.jpg' size='huge' rounded />
                    <Button className='cta-button' primary size='big' onClick={() => { this.props.history.push('/category/98') }}>SCOPRI ORA</Button>
                </div>
                <Loader inline='centered' active={this.state.loading} disabled={!this.state.loading} />
                <Suggestions products={this.state.suggestions} onAddProductClick={(id) => { this.addProductCart(id) }} cartLength={this.props.products.length} />
                <Header as='h4' color='grey' style={{ marginTop: '40px', marginBottom: '20px' }}>
                    OFFERTE DELLA SETTIMANA
                </Header>
                <Grid columns={3}>
                    <Grid.Column>
                        <Card>
                            <Card.Content textAlign='center'>
                                <Image src={product1.url_image} size='small' />
                            </Card.Content>
                            <Card.Content textAlign='center'>
                                <p>{product1.name}, {product1.brand}</p>
                                <Button basic color='green' onClick={() => { this.addProductCart(product1) }}>+ Aggiungi</Button>
                            </Card.Content>
                        </Card>
                    </Grid.Column>
                    <Grid.Column>
                        <Card>
                            <Card.Content textAlign='center'>
                                <Image src={product2.url_image} size='small' />
                            </Card.Content>
                            <Card.Content textAlign='center'>
                                <p>{product2.name}, {product2.brand}</p>
                                <Button basic color='green' onClick={() => { this.addProductCart(product2) }}>+ Aggiungi</Button>
                            </Card.Content>
                        </Card>
                    </Grid.Column>
                    <Grid.Column>
                        <Card>
                            <Card.Content textAlign='center'>
                                <Image src={product3.url_image} size='small' />
                            </Card.Content>
                            <Card.Content textAlign='center'>
                                <p>{product3.name}, {product3.brand}</p>
                                <Button basic color='green' onClick={() => { this.addProductCart(product3) }}>+ Aggiungi</Button>
                            </Card.Content>
                        </Card>
                    </Grid.Column>
                </Grid>
            </div>

        )
    }

    addProductCart = async(newProduct) => {

        const productsIds = this.props.products.map((element) => {
            return element.id;
        });
        productsIds.push(newProduct.id);

        this.setState({
            loading: true
        });

        try {
            let response = await API.updateCart(productsIds);

            if (response.data.data != null) {

                var data = response.data.data;

                this.props.addProductCart(newProduct, data.score, data.proteins, data.carbos, data.fats, data.fibers, data.goalBio, data.goalNoAdditives);
                this.getSuggestions(productsIds, data.score);

            } else {
                this.setState({
                    loading: false
                });
                console.log('Error while updating the cart info.');
            }
        } catch (error) {
            this.setState({
                loading: false
            });
            console.log('Error while getting the cart info.');
        }
        
    }

    getSuggestions = async (productsIds, score) => {

        if (productsIds.length == 0) {
            this.setState({
                suggestions: [],
                loading: false
            });

            this.props.addSuggestions([]);

            return
        }

        try {
            let response = await API.getSuggestions(productsIds, score);

            if (response.data.data != null) {

                this.setState({
                    suggestions: response.data.data,
                    loading: false
                });

                this.props.addSuggestions(response.data.data);

            } else {
                this.setState({
                    loading: false
                });
                console.log('Error while updating suggestions.');
            }
        } catch (error) {
            this.setState({
                loading: false
            });
            console.log('Error while getting suggestions.');
        }
    }
}


const mapStateToProps = state => {
    var products = [];

    state.cart_rdc.cart.forEach(
        cartElement => products.push(cartElement.product
        ));

    return {
        products: products,
        score: state.cart_rdc.score,
        suggestions: state.cart_rdc.suggestions
    }
};

const mapDispatchToProps = dispatch => {
    return {
        addProductCart: (value, score, proteinsPercentage, carbosPercentage, fatsPercentage, fibersAverageQuantity, goalBioAchieved, goalNoAdditivesAchieved) => dispatch({
            type: actionTypes.ADD_PRODUCT, 
            product: value,
            score: score,
            proteinsPercentage: proteinsPercentage,
            carbosPercentage: carbosPercentage,
            fatsPercentage: fatsPercentage,
            fibersAverageQuantity: fibersAverageQuantity,
            goalBioAchieved: goalBioAchieved,
            goalNoAdditivesAchieved: goalNoAdditivesAchieved
        }),
        addSuggestions:(suggestions) => dispatch({
            type: actionTypes.ADD_SUGGESTIONS, 
            suggestions: suggestions
        })
    }
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(Highlight);
