import { Icon, Header } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';

function Top() {

    const history = useHistory();

    return (
        <Header as='h1' inverted style={{ padding: '30px' }} onClick={() => { history.push('/') }}>
            <Icon name='cart' />
            <Header.Content>
                Il tuo supermercato online
                <Header.Subheader>
                    Costruisci una spesa equilibrata e vivi meglio.
                </Header.Subheader>
            </Header.Content>
        </Header>);
}

export default Top;
