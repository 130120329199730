import Category from './pages/Category';
import Checkout from './pages/Checkout';
import Home from './pages/Home';
import {
  HashRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import './css/App.css';

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/category/:id">
            <Category />
          </Route>
          <Route path="/checkout">
            <Checkout />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
