import { Card, Header, Image, Grid, Button } from 'semantic-ui-react';

const CategoryItems = (props) => {

    const buildProductsList = (props) => {
        return props.products.map((product) => {

            return (
                <Grid.Column key={product.id}>
                    <Card>
                        <Card.Content textAlign='center'>
                            <Image src={product.url_image} size='tiny' />
                        </Card.Content>
                        <Card.Content textAlign='center'>
                            <p>{product.name}, {product.brand}</p>
                            <Button basic color='green' onClick={()=>props.onAddProductClick(product)} >+ Aggiungi</Button>
                        </Card.Content>
                    </Card>
                </Grid.Column>
            );
        });
    }

    return (
        <div>
            <Header as='h3' textAlign='center' color='blue' style={{ textTransform: 'uppercase' }}>Prodotti</Header>
            <Grid columns={3} style={{ marginTop: '30px' }}>
                {buildProductsList(props)}
            </Grid>
        </div>
    );
}

export default CategoryItems;
