import * as actionTypes from '../actions';

const initialState = {
    cart: [],
    score: 0,
    proteinsPercentage: 0,
    carbosPercentage: 0,
    fatsPercentage: 0,
    fibersAverageQuantity: 0,
    goalBioAchieved: false,
    goalNoAdditivesAchieved: false,
    suggestions: []
};

const cartReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_PRODUCT:

            var cart = [...state.cart];
            var found = false;

            // TODO better stop when found
            cart.forEach(cartElement => {
                if (cartElement.product.id === action.product.id) {
                    cartElement.quantity++;
                    found = true;
                }
            });

            if (!found) {
                var cartElement = {
                    "product": action.product,
                    "quantity": 1
                }

                cart.push(cartElement);
            }

            return {
                ...state,
                cart: cart,
                score: action.score,
                proteinsPercentage: action.proteinsPercentage,
                carbosPercentage: action.carbosPercentage,
                fatsPercentage: action.fatsPercentage,
                fibersAverageQuantity: action.fibersAverageQuantity,
                goalBioAchieved: action.goalBioAchieved,
                goalNoAdditivesAchieved: action.goalNoAdditivesAchieved
            }

        case actionTypes.ADD_SUGGESTIONS:

            return {
                ...state,
                suggestions: action.suggestions
            }
        case actionTypes.REMOVE_PRODUCT:

            var cart = [...state.cart];
            var filteredCart = cart.filter(function(el) { return el.product.id != action.product.id; });

            if (filteredCart.length == 0) {
                filteredCart = []
            }

            return {
                ...state,
                cart: filteredCart,
                score: action.score,
                proteinsPercentage: action.proteinsPercentage,
                carbosPercentage: action.carbosPercentage,
                fatsPercentage: action.fatsPercentage,
                fibersAverageQuantity: action.fibersAverageQuantity,
                goalBioAchieved: action.goalBioAchieved,
                goalNoAdditivesAchieved: action.goalNoAdditivesAchieved
            }

        case actionTypes.UPDATE_PRODUCT_QUANTITY:

            var cart = [...state.cart];

            // TODO update product quantity

            return {
                ...state,
                cart: cart
            }
        default:
            return state;
    }
};

export default cartReducer;
