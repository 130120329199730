import { Component } from 'react';
import { Header, List, Icon, Loader } from 'semantic-ui-react';
import { withRouter } from "react-router-dom";
import API from '../api';
import '../css/Categories.css';

class CategoriesList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            categories: [],
            loading: false
        };
    }

    componentDidMount() {
        this.getCategories();
    }

    render() {
        return (
            <div>
                <Header as='h3' textAlign='center' color='blue'>SUPERMERCATO</Header>
                <Loader inline='centered' active={this.state.loading} disabled={!this.state.loading} />
                <List divided selection verticalAlign='middle'>
                    {this.buildCategories()}
                </List>
            </div >
        );
    }

    buildCategories = () => {

        return this.state.categories.map((category) => {

            return (
                <List.Item active={category.id == this.props.categoryId}
                    key={category.id}
                    onClick={() => this.props.onCategorySelected(category.id)}>
                    <List.Content floated='right'>
                        <Icon name="angle right" size='large' />
                    </List.Content>
                    <List.Content>{category.name}</List.Content>
                </List.Item>
            );
        });
    }

    getCategories = async () => {
        this.setState({
            loading: true
        });

        try {
            let response = await API.getCategories();

            this.setState({
                loading: false
            });

            if (response.data.data != null) {
                this.setState({
                    categories: response.data.data
                });
            } else {
                console.log('Error while getting the categories list.');
            }
        } catch (error) {
            console.log('Error while getting the categories list.');
        }
    }
}

export default withRouter(CategoriesList);
