import { Button} from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';

function CartAction(props) {

    const history = useHistory();
    const action = props.action;

    return (
        <Button primary size='big'
            style={{ marginTop: '20px', marginBottom: '20px' }}
            onClick={() => history.push(action === 'checkout' ? '/checkout' : '/')}>
            {action === 'checkout' ? 'CHECKOUT' : 'ACQUISTA'}
        </Button>
    );
}

export default CartAction;
