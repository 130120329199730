import { Component } from 'react';
import { Container, Segment, Grid, Divider, Loader } from 'semantic-ui-react';
import Top from '../components/Top';
import CheckoutTable from '../components/CheckoutTable';
import Cart from '../components/Cart';
import CartAction from '../components/CartAction';
import Suggestions from '../components/Suggestions';
import { connect } from 'react-redux';
import * as actionTypes from '../store/actions';
import API from '../api';

class Checkout extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading:false
        };
    }

    removeProductCart = async(removedProduct) => {

        var productsIds = this.props.products.map((element) => {
            return element.id;
        });
        productsIds = productsIds.filter(function(el) { return el != removedProduct.id; });

        this.setState({
            loading: true
        });

        try {
            let response = await API.updateCart(productsIds);

            if (response.data.data != null) {

                var data = response.data.data;

                this.props.removeProductCart(removedProduct, data.score, data.proteins, data.carbos, data.fats, data.fibers, data.goalBio, data.goalNoAdditives);
                this.getSuggestions(productsIds, data.score);

            } else {
                this.setState({
                    loading: false
                });
                console.log('Error while updating the cart info.');
            }
        } catch (error) {
            this.setState({
                loading: false
            });
            console.log('Error while getting the cart info.');
        }
        
    }

    addProductCart = async(newProduct) => {

        const productsIds = this.props.products.map((element) => {
            return element.id;
        });
        productsIds.push(newProduct.id);

        this.setState({
            loading: true
        });

        try {
            let response = await API.updateCart(productsIds);

            if (response.data.data != null) {

                var data = response.data.data;

                this.props.addProductCart(newProduct, data.score, data.proteins, data.carbos, data.fats, data.fibers, data.goalBio, data.goalNoAdditives);
                this.getSuggestions(productsIds, data.score);

            } else {
                this.setState({
                    loading: false
                });
                console.log('Error while updating the cart info.');
            }
        } catch (error) {
            this.setState({
                loading: false
            });
            console.log('Error while getting the cart info.');
        }   
    }

    getSuggestions = async (productsIds, score) => {

        if (productsIds.length == 0) {
            this.setState({
                suggestions: [],
                loading: false
            });

            this.props.addSuggestions([]);

            return
        }

        try {
            let response = await API.getSuggestions(productsIds, score);

            if (response.data.data != null) {

                this.setState({
                    loading: false
                });

                this.props.addSuggestions(response.data.data);

            } else {
                this.setState({
                    loading: false
                });
                console.log('Error while updating suggestions.');
            }
        } catch (error) {
            this.setState({
                loading: false
            });
            console.log('Error while getting suggestions.');
        }
    }

    render() {
        return (
            <div>
                <Container fluid>
                    <Grid centered stackable>
                        <Grid.Row color='blue'>
                            <Top />
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={11}>
                                <Segment raised>
                                    <CheckoutTable onRemoveProductClick={(removedProduct) => { this.removeProductCart(removedProduct) }} />
                                    <Loader inline='centered' active={this.state.loading} disabled={!this.state.loading} />
                                    <Suggestions products={this.props.suggestions} onAddProductClick={(id) => { this.addProductCart(id) }} cartLength={this.props.products.length}/>
                                </Segment>
                            </Grid.Column>
                            <Grid.Column width={4} textAlign='center'>
                                <Segment raised>
                                    <Cart />
                                    <Divider />
                                    <CartAction />
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = state => {
    var products = [];

    state.cart_rdc.cart.forEach(
        cartElement => products.push(cartElement.product
        ));

    return {
        products: products,
        score: state.cart_rdc.score,
        suggestions: state.cart_rdc.suggestions
    }
};

const mapDispatchToProps = dispatch => {
    return {
        addProductCart: (value, score, proteinsPercentage, carbosPercentage, fatsPercentage, fibersAverageQuantity, goalBioAchieved, goalNoAdditivesAchieved) => dispatch({
            type: actionTypes.ADD_PRODUCT,
            product: value,
            score: score,
            proteinsPercentage: proteinsPercentage,
            carbosPercentage: carbosPercentage,
            fatsPercentage: fatsPercentage,
            fibersAverageQuantity: fibersAverageQuantity,
            goalBioAchieved: goalBioAchieved,
            goalNoAdditivesAchieved: goalNoAdditivesAchieved
        }),
        removeProductCart: (value, score, proteinsPercentage, carbosPercentage, fatsPercentage, fibersAverageQuantity, goalBioAchieved, goalNoAdditivesAchieved) => dispatch({
            type: actionTypes.REMOVE_PRODUCT,
            product: value,
            score: score,
            proteinsPercentage: proteinsPercentage,
            carbosPercentage: carbosPercentage,
            fatsPercentage: fatsPercentage,
            fibersAverageQuantity: fibersAverageQuantity,
            goalBioAchieved: goalBioAchieved,
            goalNoAdditivesAchieved: goalNoAdditivesAchieved
        }),
        addSuggestions:(suggestions) => dispatch({
            type: actionTypes.ADD_SUGGESTIONS, 
            suggestions: suggestions
        })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
