import { Component } from 'react';
import { Divider, Header, List, Loader, Image } from 'semantic-ui-react';
import Circle from './Circle';
import CartGoals from './CartGoals';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import API from '../api';
import { connect } from 'react-redux';
import * as actionTypes from '../store/actions';
import '../css/Cart.css';
import 'react-circular-progressbar/dist/styles.css';
import GradeImageND from '../images/grade_nd.png';
import GradeImage1 from '../images/grade_1.png';
import GradeImage2 from '../images/grade_2.png';
import GradeImage3 from '../images/grade_3.png';
import GradeImage4 from '../images/grade_4.png';
import GradeImage5 from '../images/grade_5.png';

class Cart extends Component {

    constructor(props) {
        super(props);

        this.state = {
            color: 'grey',
            score: "ND",
            proteinsPercentage: 0,
            carbosPercentage: 0,
            fatsPercentage: 0,
            fibersAverageQuantity: 0,
            goalBioAchieved: false,
            goalNoAdditivesAchieved: false,
            products: [],
            loading: false
        };
    }

    updateCart = async (productsIds) => {
        this.setState({
            loading: true
        });

        try {
            let response = await API.updateCart(productsIds);

            this.setState({
                loading: false
            });

            if (response.data.data != null) {

                var data = response.data.data;

                var color = 'grey';

                if (data.score < 4) {
                    color = 'red';
                } else if (data.score < 6) {
                    color = 'orange';
                } else if (data.score >= 6) {
                    color = 'green';
                }

                this.setState({
                    color: color,
                    score: data.score,
                    proteinsPercentage: data.proteins,
                    carbosPercentage: data.carbos,
                    fatsPercentage: data.fats,
                    fibersAverageQuantity: data.fibers,
                    goalBioAchieved: data.goalBio,
                    goalNoAdditivesAchieved: data.goalNoAdditives
                });
            } else {
                console.log('Error while updating the cart info.');
            }
        } catch (error) {
            console.log('Error while getting the cart info.');
        }
    }

    componentDidMount() {
        var productsIds = [];

        this.props.products.forEach(product => {
            productsIds.push(product.id);
        });

        if (productsIds.length > 0) {
            this.updateCart(productsIds);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.products !== this.props.products) {

            var productsIds = [];

            this.props.products.forEach(product => {
                productsIds.push(product.id);
            });

            if (productsIds.length > 0) {
                this.updateCart(productsIds);
            } else {
                this.setState({
                    color: 'grey',
                    score: "ND",
                    proteinsPercentage: 0,
                    carbosPercentage: 0,
                    fatsPercentage: 0,
                    fibersAverageQuantity: 0,
                    goalBioAchieved: false,
                    goalNoAdditivesAchieved: false,
                    products: [],
                    loading: false
                });
            }
        }
    }

    render() {
        var imageGrade = <Image src={GradeImageND} />
        if (this.state.score < 2) {
            imageGrade = <Image src={GradeImage1} />
        } else if (this.state.score < 4) {
            imageGrade = <Image src={GradeImage2} />
        } else if (this.state.score < 6) {
            imageGrade = <Image src={GradeImage3} />
        } else if (this.state.score < 8) {
            imageGrade = <Image src={GradeImage4} />
        } else if (this.state.score >= 8) {
            imageGrade = <Image src={GradeImage5} />
        }

        return (
            <div>
                <Header as='h3' color='blue'>CARRELLO</Header>
                <Loader inline='centered' active={this.state.loading} disabled={!this.state.loading} />
                <Circle color={this.state.color} score={this.state.score} />
                {imageGrade}
                <Header as='h4' color='grey' style={{ marginTop: '40px' }}>
                    Questo indice ti segnala quanto è bilanciato il tuo carrello dal punto di vista nutrizionale.
                </Header>
                <List horizontal style={{ marginTop: '20px' }}>
                    <Divider />
                    <List.Item>
                        <p className='legend'>Proteine</p>
                        <div className='nutrient'>
                            <CircularProgressbar value={this.state.proteinsPercentage} text={`${this.state.proteinsPercentage}%`} styles={
                                buildStyles({
                                    textSize: '20px',
                                    trailColor: '#eee',
                                    textColor: 'blue',
                                    pathColor: 'blue'
                                })} />
                        </div>
                    </List.Item>
                    <List.Item>
                        <p className='legend'>Carboidrati</p>
                        <div className='nutrient'>
                            <CircularProgressbar value={this.state.carbosPercentage} text={`${this.state.carbosPercentage}%`} styles={
                                buildStyles({
                                    textSize: '20px',
                                    trailColor: '#eee',
                                    textColor: 'green',
                                    pathColor: 'green'
                                })} />
                        </div>
                    </List.Item>
                    <List.Item>
                        <p className='legend'>Grassi</p>
                        <div className='nutrient'>
                            <CircularProgressbar value={this.state.fatsPercentage} text={`${this.state.fatsPercentage}%`} styles={
                                buildStyles({
                                    textSize: '20px',
                                    trailColor: '#eee',
                                    textColor: 'orange',
                                    pathColor: 'orange'
                                })} />
                        </div>
                    </List.Item>
                </List>
                <Divider />
                <CartGoals goalBioAchieved={this.state.goalBioAchieved} goalNoAdditivesAchieved={this.state.goalNoAdditivesAchieved} />
            </div>
        );
    }
}

const mapStateToProps = state => {

    var products = [];

    state.cart_rdc.cart.forEach(
        cartElement => products.push(cartElement.product
        ));

    return {
        products: products,
        color: state.cart_rdc.color,
        score: state.cart_rdc.score,
        proteinsPercentage: state.cart_rdc.proteinsPercentage,
        carbosPercentage: state.cart_rdc.carbosPercentage,
        fatsPercentage: state.cart_rdc.fatsPercentage,
        fibersAverageQuantity: state.cart_rdc.fibersAverageQuantity,
        goalBioAchieved: state.cart_rdc.goalBioAchieved,
        goalNoAdditivesAchieved: state.cart_rdc.goalNoAdditivesAchieved
    }
};

const mapDispatchToProps = dispatch => {
    return {
        addProductCart: (value) => dispatch({ type: actionTypes.ADD_PRODUCT, product: value }),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
